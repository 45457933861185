import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const news = graphql`
  query NewsQuery {
   allContentfulNews {
    edges {
      node {
        title,
        content {
          json
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Accueil" />
    
    <div style={{ maxWidth: `1200px`, marginBottom: `1.45rem` }}>
      {data.allContentfulNews.edges.map(({ node }) =>
        <div>
          <h2>{node.title}</h2>
          <p>{documentToReactComponents(node.content.json)}</p>
        </div>)
      }
    </div>
    
  </Layout>
)

export default IndexPage
